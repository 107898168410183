
import { Vue, setup, Options } from 'vue-class-component'
import useEmitter from '@/composables/useEmitter'
import useAccount from '@/composables/account'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import BlockPreloader from '@/components/core/BlockPreloader.vue'
import router from '@/router'

@Options({
  components: {
    BlockPreloader,
  },
})
export default class Login extends Vue {
  login = ''
  password = ''

  pageContext = setup(() => {
    const { emitter } = useEmitter()
    const { token, isAuthUserLoading, authUser, authUserErrorBag } = useAccount()
    return { emitter, token, isAuthUserLoading, authUser, errors: authUserErrorBag }
  })

  doSendUserInfo(): void {
    this.pageContext.authUser(this.login, this.password)
  }

}
