
import { Vue, Options } from 'vue-class-component'

@Options({
  props: {
    active: Boolean,
  },
  watch: {
    active(value: boolean) {
      this.isActive = value
    },
  },
})
export default class BlockPreloader extends Vue {
  private isActive = false
}
